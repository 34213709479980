import React from "react"
import { navigate } from "gatsby"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Stack from "../../../../../components/stack"
import Inline from "../../../../../components/inline"
import Note from "../../../../../components/note"
import CheckboxButton from "../../../../../components/checkbox-button"
import { getCourseNavigation } from "../../../../../store/courses"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "reiseerinnerung",
          chapterId: "01-suedfrankreich",
          taskId: "reiseformen",
        })
        navigate("/kurse/reiseerinnerung/01-suedfrankreich/reiseformen/loesung")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" name="" value="submit" />}
    >
      <Seo title="Reiseformen im Gedicht" />

      <Stack space={10}>
        <Heading as="h1" level={2}>
          Reiseformen im Gedicht
        </Heading>
        <Note variant="task">
          Markiere die Formen des Reisens, die im Gedicht vorkommen, indem du
          sie anklickst. Klicke erneut darauf, wenn du die Markierung wieder
          entfernen möchtest.
        </Note>
        <Inline>
          <CheckboxButton name="Seefahrt" value="Seefahrt">
            Schiff -/ Seefahrt
          </CheckboxButton>
          <CheckboxButton name="Wanderung" value="Wanderung">
            Wanderung
          </CheckboxButton>
          <CheckboxButton name="Bergbesteigung" value="Bergbesteigung">
            Bergbesteigung
          </CheckboxButton>
          <CheckboxButton name="Kutschfahrt" value="Kutschfahrt">
            Kutschfahrt
          </CheckboxButton>
          <CheckboxButton name="Gedankenreise" value="Gedankenreise">
            Gedankenreise
          </CheckboxButton>
          <CheckboxButton name="Bahnfahrt" value="Bahnfahrt">
            Bahnfahrt
          </CheckboxButton>
          <CheckboxButton name="Zeitreise" value="Zeitreise">
            Zeitreise
          </CheckboxButton>
          <CheckboxButton name="Städte-Reise" value="Städte-Reise">
            Städte-Reise
          </CheckboxButton>
          <CheckboxButton name="Pilgerreise" value="Pilgerreise">
            Pilgerreise
          </CheckboxButton>
          <CheckboxButton name="Expedition" value="Expedition">
            Expedition
          </CheckboxButton>
          <CheckboxButton name="Reiseerinnerung" value="Reiseerinnerung">
            Reiseerinnerung
          </CheckboxButton>
          <CheckboxButton name="Strandurlaub" value="Strandurlaub">
            Strandurlaub
          </CheckboxButton>
          <CheckboxButton name="Flugreise" value="Flugreise">
            Flugreise
          </CheckboxButton>
          <CheckboxButton name="Bildungsreise" value="Bildungsreise">
            Bildungsreise
          </CheckboxButton>
        </Inline>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
